.Privacy-body {
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 88vw;
  max-width: 720px;
  color: #606060;
}

a,
a:visited {
  color: #ff80d6;
  text-decoration: none;
}

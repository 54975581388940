.App {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

.App-body {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: flex-start;
}

.App-section {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
  
}

.App-lockup {
  width: 60vw;
  max-width: 350px;
}

.App-private-label {
  font-size: calc(5px + 1vmin);
  color: #ff21a944;
}

.App-link,
.App-link:visited {
  color: #aeaeae;
  text-decoration: none;
}

/* .App-header { */
/* width: 10000px; */
/* display: flex;
  flex-direction: row;
  justify-content: flex-start; */
/* align-self: flex-start; */
/* } */

.App-logo {
  width: 3%;
  min-width: 25px;
  margin: 8px;
}

.App-footer-element {
  font-size: calc(5px + 1vmin);
  margin-right: 18px;
  margin-left: 18px;
}

.App-footer-active-element {
  font-weight: bolder;
}

.App-footer {
  /* font-family: 'Source Sans Pro', sans-serif; */
  color: #bebebe;
}

.App-footer-toprow {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.App-copyright {
  font-size: calc(4px + 1vmin);
  text-align: center;
  padding: 36px;
}

/* .App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} 

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
